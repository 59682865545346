import React from 'react';
import './Home.css';
import ContentSection from "./ContentSection/ContentSection";
import Navbar from "./Navbar/Navbar";

import content from "../../data/ExampleContent.json"

export default function Home() {
    return (
        <div className="body">
            <h1 className="body--title">Title of the Page</h1>
            <div className="body--main-content">
                <Navbar/>
                <div className="body--content">
                    {content.map((section) => (<ContentSection key={section.id} props={section}/>))}
                </div>
            </div>
        </div>
    )
}