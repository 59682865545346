import React from 'react';
import './Header.css';
import logo from '../../logo.svg';

export default function Header() {
    return (
        <header>
            <img src={logo} className="header--logo" alt="logo"/>
            <h1 className="header--title">Finance made Easy</h1>
        </header>
    )
}