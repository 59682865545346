import React from 'react';
import './ContentSection.css'

interface ContentProps {
    props: {id: number, title: string, content: object}
}

export default function ContentSection({props}: ContentProps) {
    return(
        <div className="content-section">
            <h2 className="content-section--title">{props.title}</h2>
            <div className="content-section--body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et ex nisl. Pellentesque lacinia efficitur dolor. Nunc non accumsan neque. Nulla tristique auctor congue. Nam id risus in tortor finibus lobortis id ut lorem. Etiam suscipit porttitor libero. Proin ex ligula, blandit eget dolor vitae, consectetur laoreet tellus. Praesent erat orci, consectetur id consequat non, convallis in leo. Aliquam bibendum nibh sit amet augue aliquet, tempus porta libero finibus. Curabitur vestibulum eros vel accumsan imperdiet.</p>
                <p>Nunc eu ligula id dui dictum pellentesque non et sem. Etiam finibus sagittis lorem eu sodales. Nulla eu consectetur lorem. Fusce a velit ut tellus accumsan convallis at id diam. Mauris pellentesque dui et lorem efficitur, a luctus velit imperdiet. Donec vehicula massa non nisl efficitur aliquet. Fusce fringilla elit a lorem auctor ornare. Cras molestie mollis massa, nec aliquet tellus volutpat sed. Integer mattis vehicula eros, id bibendum purus placerat ac. Etiam aliquet cursus semper. Aenean scelerisque mollis porta. Praesent tincidunt sapien facilisis enim luctus vestibulum. In cursus orci eget sem tempus, id molestie diam dignissim. Sed scelerisque lacinia lectus. Aliquam sodales bibendum urna. Sed id neque blandit, vulputate nisi vitae, pretium quam.</p>
                <p>Mauris ac venenatis risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut sit amet magna at enim euismod venenatis eget sed eros. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo dui sit amet posuere efficitur. Quisque sagittis faucibus elit vel scelerisque. Proin auctor nec arcu eu varius. Donec fringilla mollis dui, sed tempus turpis iaculis eu. Etiam vel sapien ultrices, mollis augue sit amet, dapibus nulla. Nullam gravida sed enim quis ultrices. Nullam in aliquam felis, id aliquet mi. Pellentesque a fermentum dui. Cras facilisis dui vel mauris placerat, vel sodales arcu auctor. Vivamus cursus vestibulum metus, sed viverra dolor. Suspendisse eu lectus dui.</p>
            </div>
        </div>
    )
}
